import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

import { AiOutlineArrowLeft } from 'react-icons/ai';
import UserActions from 'store/modules/auth/actions';

import Input from 'components/Input/Login';
import Button from 'components/Button/Login';

import api from 'services/api';

import { Logo } from './styles';

export default function Login() {
  const Steps = {
    LOGIN: 0,
    FORGOT_PASSWORD: 1,
  };

  const initialStep = Steps.LOGIN;
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [loadingPassword, setLoadingPassword] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [msgError, setMsgError] = useState('');

  const history = useHistory();

  const formRef = useRef();

  const auth = useSelector((store) => store.auth);
  const dispatch = useDispatch();

  async function validateEmail(email) {
    dispatch(UserActions.validateEmail(email, formRef.current));
  }

  async function submit({ email, password }) {
    const { state } = history.location;
    const from = state ? state.from : '/';

    dispatch(
      UserActions.signIn({ email, password }, formRef.current, {
        history,
        from,
      })
    );
  }

  async function doPasswordRecovery(email) {
    setLoadingPassword(true);
    try {
      const response = await api.post('forgot-password', {
        email,
        type: 'carrier',
      });

      if (response.status === 200) {
        setIsSucceed(true);
      }
    } catch (ex) {
      setIsError(true);
      setMsgError('Algo deu errado, tente novamente mais tarde.');
    } finally {
      setLoadingPassword(false);
    }
  }

  function resetMessages() {
    setIsError(false);
    setIsSucceed(false);
    setMsgError('');
  }

  function handleSubmit(data) {
    const { email, password } = data;

    if (currentStep === Steps.LOGIN) {
      if (!auth.valid) {
        validateEmail(email, formRef);
      } else {
        submit({ email, password }, formRef, history);
      }
    } else {
      doPasswordRecovery(email);
    }
  }

  function changeStep(step) {
    resetMessages();
    setCurrentStep(step);
  }

  function handleButtonText() {
    if (currentStep === Steps.LOGIN) {
      if (auth.valid) return 'Entrar';
      return 'Próximo';
    }
    return 'Enviar e-mail';
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      {currentStep !== Steps.LOGIN ? (
        <Row className="justify-content-left">
          <Button
            variant="link"
            className="forgot-arrow"
            onClick={() => changeStep(Steps.LOGIN)}
          >
            <AiOutlineArrowLeft />
          </Button>
        </Row>
      ) : (
        ''
      )}

      <Row className="justify-content-center py-4" >
        <Logo src="/images/grupo_coopercarga.png" alt="Coopercarga" />
        <Logo src="/images/Logo-CooperPlace.png" alt="Cooperplace" />
      </Row>
      <Input
        label={
          currentStep === Steps.LOGIN
            ? 'Entrar'
            : 'E-mail de login para recuperar a senha'
        }
        type="email"
        name="email"
        placeholder="Digite seu e-mail"
        onChange={() => {
          dispatch(UserActions.validateReset());
        }}
        vertical
      />
      {currentStep === Steps.LOGIN
        ? auth.valid && (
          <Input
            autoFocus
            type="password"
            name="password"
            placeholder="Senha"
            className="mt-1"
            vertical
          />
        )
        : ''}
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          loading={auth.loading || loadingPassword}
        >
          {handleButtonText()}
        </Button>
        {isError && <Alert variant="danger" className='mt-2'>{msgError}</Alert>}
        {isSucceed && (
          <Alert variant="success" className='mt-2'>
            Enviamos o link para alteração de senha para seu e-mail !
          </Alert>
        )}
      {(currentStep === Steps.LOGIN) && (
        <Button
          as="a"
          className="w-100 text-center d-block text-center mb-1 mt-1 text-decoration-none"
          variant="success"
          target="_blank"
          href={process.env.REACT_APP_REGISTER_FORM_URL}
        >
          Solicitar cadastro
        </Button>
      )}
      {auth.valid && currentStep === Steps.LOGIN && (
        <div>
          <Button
            role="button"
            as="a"
            variant="link"
            className="forgot-password d-block text-center"
            onClick={() => changeStep(Steps.FORGOT_PASSWORD)}
          >
            Esqueci minha senha
          </Button>
        </div>
      )}
    </Form>
  );
}
